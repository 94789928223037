<template>
  <div @keydown.enter="setGrade" v-if="!showLoader">
    <v-row class="justify-space-around align-center mt-n16 text-center">
      <v-col lg="3">
        <p>{{ $t("student name") }}</p>
        <h2 class="primary--text">{{ data.student.full_name }}</h2>
      </v-col>
      <v-col lg="3">
        <p>{{ $t("exercise name") }}</p>
        <h2 class="primary--text">{{ data.exercise.name }}</h2>
      </v-col>
      <v-col lg="3">
        <p>{{ $t("exercise type") }}</p>
        <h2 class="primary--text">{{ $t(data.exercise.type) }}</h2>
      </v-col>
      <v-col lg="3">
        <p>{{ $t("exercise serial") }}</p>
        <h2 class="primary--text">{{ data.exercise.serial }}</h2>
      </v-col>
      <v-col lg="3">
        <p>{{ $t("time to Time taken to solve") }}</p>
        <h2 class="primary--text">
          {{ data.solution.time }} {{ $t("second") }}
        </h2>
      </v-col>
    </v-row>
    <v-divider class="my-8 mb-16"></v-divider>
    <div>
      <video-preview
        v-if="data.exercise.type == 'coloring'"
        :data="data.solution.files[0]"
        :cancelEnable="false"
        :fileServer="'teacher/file'"
      ></video-preview>
      <v-row
        v-if="data.exercise.type == 'voice_record'"
        class="justify-center my-2"
      >
        <audio-record
          :data="data.solution.files[0]"
          :cancelEnable="false"
          :fileServer="'teacher/file'"
        ></audio-record>
      </v-row>
      <v-row class="justify-center">
        <v-col lg="2">
          <v-text-field
            v-model="grade"
            type="number"
            dense
            rounded
            :label="$t('student grade')"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="justify-center mt-n10">
        <v-col class="d-flex justify-center" lg="2">
          <v-btn
            @click="setGrade"
            :loading="loader"
            class="ma-2"
            rounded
            color="primary"
            :disabled="disabled"
          >
            {{ $t("agree") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
  <pulse-loader
    v-else
    :loading="true"
    :color="$vuetify.theme.themes.light.primary"
    size="20px"
  />
</template>

<script>
import { Solution } from "@/store/teacher/solutions";
import audioRecord from "@/components/mycomponent/preview/audio.vue";
export default {
  components: {
    audioRecord,
  },
  data() {
    return {
      Solution,
      grade: null,
    };
  },
  methods: {
    async setGrade() {
      if (this.disabled) return;
      await Solution.edit(this.id, { grade: this.grade });
      this.$router.push("/solutions");
    },
  },
  computed: {
    name() {
      return Solution;
    },
    id() {
      return this.$route.params.id;
    },
    data() {
      return this.name.tableData.showData;
    },
    loader() {
      return this.name.postState.loading;
    },
    showLoader() {
      return Solution.showState.loading;
    },
    disabled() {
      return this.grade == null || this.grade == "";
    },
  },
  async created() {
    await this.name.show(this.id);
    if (this.data.student_grade) this.grade = this.data.student_grade;
  },
};
</script>
<style lang="scss" scoped>
h1,
h2 {
  font-weight: 900;
  font-size: 1.2vw;
}

p {
  font-weight: 600;
}
</style>
